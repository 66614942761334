// import a plugin
import pageBuilder from "./Builder/index"
import { graphql } from "gatsby"
//  import "bootstrap/dist/css/bootstrap.min.css"
// import sortBy from "lodash/sortBy"
// import SSRProvider from "react-bootstrap/SSRProvider"
import { ProviderAPI, usePageContext } from "./PageStateContext"
import { setNotificationRef } from "./Builder/Components/Notification/Notify"
import {
  Suspense,
  useEffect,
  useRef,
  createContext,
  useMemo,
} from "../hooks/stateHooks"
import React from "../hooks/reactImport"
import config from "../primeagile.config"
import { Head } from "./Builder/Head"
import { sortItem } from "../hooks/SortItem"
import { ApolloClient, ApolloProvider, InMemoryCache } from "../hooks/apollo"
import { useDispatch } from "react-redux"
import { setCaptchaResponse } from "../redux/slices/states"

export const PageStateContext = createContext(null)

export default function PrimeAgile({ data, pageContext }) {
  let TREE =
    data.primeAgile?.layoutWithObjectTree?.uiObjectWithChildTreeR
      ?.uiObjectChildTree

  let LayoutCSS =
    data.primeAgile?.layoutWithObjectTree?.uiObjectWithChildTreeR?.jsonData?.css
      ?.fileUrl

  TREE = pageBuilder(sortItem(TREE))

  const client = new ApolloClient({
    uri: `${config.API_URL}/graphql`,
    headers: {
      "X-TENANT-ID": `${process.env.GATSBY_BUILDSITE}`,
    },
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            pages: {
              pages: {
                keyArgs: [],
                merge(existing, incoming, { args: { offset = 0 } }) {
                  // Slicing is necessary because the existing data is
                  // immutable, and frozen in development.
                  const merged = existing ? existing.slice(0) : []
                  for (let i = 0; i < incoming.pages.length; ++i) {
                    merged[offset + i] = incoming[i]
                  }
                  return merged
                },
              },
            },
          },
        },
      },
    }),
  })

  const PrimeAgileHelmet = React.lazy(() =>
    import("./Helmet/index").then(module => {
      return { default: module.default }
    })
  )

  const FontSchemeManager = React.lazy(() =>
    import("./FontScheme/index").then(module => {
      return { default: module.default }
    })
  )

  const ErrorBoundary = React.lazy(() =>
    import("./ErrorBoundary/index").then(module => {
      return { default: module.default }
    })
  )

  const NotificationManager = React.lazy(() =>
    import("./Builder/Components/Notification/NotificationManager").then(
      module => {
        return { default: module.default }
      }
    )
  )


  const notificationRef = useRef()
  const dispatch = useDispatch()
  var verifyCallba = function (response) {
    dispatch(setCaptchaResponse(response))
  }

  useEffect(() => {
    setNotificationRef(notificationRef)
  }, [notificationRef])


  return (
    <>
      {/* <SSRProvider> */}
      <ApolloProvider client={client}>
        <Suspense fallback={<div />}>
          <NotificationManager ref={notificationRef} />
          <ErrorBoundary>
            <ProviderAPI value={pageContext} id="primeagile__entry">
              <FontSchemeManager fontScheme={pageContext.fontScheme}>
                <Head
                  pageContext={pageContext}
                  LayoutCSS={LayoutCSS}
                  pageCss={pageContext?.pageCss?.fileUrl}
                />
                <PrimeAgileHelmet
                  pageContext={pageContext}
                  LayoutCSS={LayoutCSS}
                  pageCss={pageContext?.pageCss?.fileUrl}
                />
                {/* {pageContext?.captchaSiteKey && pageContext?.loadcaptha ? (
                  <GoogleReCaptchaProvider
                    reCaptchaKey={pageContext?.captchaSiteKey}
                    scriptProps={{
                      async: false, // optional, default to false,
                      defer: true, // optional, default to false
                      appendTo: "head", // optional, default to "head", can be "head" or "body",
                      nonce: undefined, // optional, default undefined
                    }}
                  >
                    <GoogleReCaptcha onVerify={verifyCallba} />
                    {TREE}
                  </GoogleReCaptchaProvider>
                ) : ( */}
                {TREE}
                {/* )} */}
              </FontSchemeManager>
            </ProviderAPI>
          </ErrorBoundary>
        </Suspense>
      </ApolloProvider>
      {/* </SSRProvider> */}
    </>
  )
}

export const query = graphql`
  query layoutTree(
    $site: String
    $objectDataDefinitionId: String
    $locale: String
  ) {
    primeAgile {
      layoutWithObjectTree(
        objectDataDefinitionId: $objectDataDefinitionId
        layoutSetId: $site
        locale: $locale
      ) {
        css {
          fileId
          fileName
          fileType
          fileUrl
        }
        uiObjectWithChildTreeR {
          uiObjectChildTree
          jsonData
          type
          objectName
        }
      }
    }
  }
`
